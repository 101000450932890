import { useCallback, useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { ethers } from "ethers"
import ConnectionContainer from './Connection'

const Univ_ABI = require('./abi/UNIV.json')

const provider = new ethers.providers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc')

const Univ_Contract = new ethers.Contract("0x959b88966fC5B261dF8359961357d34F4ee27b4a", Univ_ABI, provider)


function web3StringToBytes32(text) {
    var result = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text));
    while (result.length < 66) { result += '0'; }
    if (result.length !== 66) { throw new Error("invalid web3 implicit bytes32"); }
    return result;
}

function GetApiKey() {

    const { signer, address, connect } = ConnectionContainer.useContainer()

    // console.log('signing secret key...') 
    // const secret_signing_key = ethers.utils.keccak256(ethers.utils.hexlify(ethers.utils.toUtf8Bytes(("sn0w$!GhT_S!gN!nG_$tR!Ng"))))
    // console.log('signed message: ', secret_signing_key) 

    let connected = address === null ? false : true


    const [apiKey, setApiKey] = useState(null)

    const updateApiKey = useCallback(async () => {

        // Get leaderboard data
        console.log('address value: ', address) 
        let currentValue = await Univ_Contract.balanceOf(address);
        console.log('value: ', currentValue) 

        setApiKey(currentValue)
    }, [setApiKey])

    useEffect(() => {
        if (apiKey !== null) {
            return
        }
        updateApiKey()
    }, [apiKey, updateApiKey])

    return {
        apiKey,
        updateApiKey
    }
}

export default createContainer(GetApiKey)