
import { useCallback, useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { ethers } from "ethers"
import useWebSocket, { ReadyState } from 'react-use-websocket';


function useConnection() {
    let last_address = null
    const [signer, setSigner] = useState(null)
    const [address, setAddress] = useState(null)



    const connect = useCallback(async () => {
        
        console.log('test ', last_address)
        if (last_address == null)
        {
            let web3Provider;
            try {
                web3Provider = new ethers.providers.Web3Provider(
                    window.ethereum
                );
                await web3Provider.send("eth_requestAccounts", null);
                let localSigner = web3Provider.getSigner();
                setSigner(localSigner);
                const addr = await localSigner.getAddress()
                setAddress(addr)

                // const message = "Sign this message to authenticate your wallet with Snowsight."
                // const signed_message = await localSigner.signMessage(message)
                
                // const orig_address = ethers.utils.verifyMessage(message, signed_message)
                // console.log("signing address:", orig_address)
                last_address = addr
                await web3Provider.send('wallet_switchEthereumChain', [{ chainId: '0xA86A' }])
            } catch (e) {
                return;
            }
        }   
        // else
        // {
        //     last_address = null
        //     setSigner(null);
        //     setAddress(null)
        //     return;
        // }
    }, [])

    useEffect(() => {
        if (signer === null) {
            connect()
        }
    }, [signer, connect])

    return {
        signer,
        address,
        connect
    }
}

export default createContainer(useConnection)