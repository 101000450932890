
import { useCallback, useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { ethers } from "ethers"
import useWebSocket, { ReadyState } from 'react-use-websocket';


function useConnection() {
    
    // const [socketUrl, setSocketUrl] = useState('wss://demo.piesocket.com/v3/channel_1?api_key=oCdCMcMPQpbvNjUIzqtvF1d2X2okWpDQj4AwARJuAgtjhzKxVEjQU6IdCjwm&notify_self');
    // const [messageHistory, setMessageHistory] = useState(null);

    // useEffect(function useConnect() {

    //     let web3Provider;
    //     try {
    //         web3Provider = new ethers.providers.Web3Provider(
    //             window.ethereum
    //         );
    //     } catch (e) {
    //         return;
    //     }
            
    //     const {
    //         sendMessage,
    //         sendJsonMessage,
    //         lastMessage,
    //         lastJsonMessage,
    //         readyState,
    //         getWebSocket
    //     } = useWebSocket(socketUrl);



    //     web3Provider.send("eth_requestAccounts", null);
    //     let localSigner = web3Provider.getSigner();

    //     const message = "Sign this message to authenticate your wallet with Snowsight."
    //     const signed_message = localSigner.signMessage(message)
        
    //     const orig_address = ethers.utils.verifyMessage(message, signed_message)
    //     console.log("signing address:", orig_address)

    //     const handleClickChangeSocketUrl = useCallback(() =>
    //     setSocketUrl('wss://demo.piesocket.com/v3/channel_1?api_key=oCdCMcMPQpbvNjUIzqtvF1d2X2okWpDQj4AwARJuAgtjhzKxVEjQU6IdCjwm&notify_self'), []);

    //     const handleClickSendMessage = useCallback(() =>
    //     sendMessage('Hello'), []);

    //     setMessageHistory('test');


    // }, [])

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    // // useEffect(() => {
    // //     if (lastMessage !== null) {
    // //             setMessageHistory(prev => prev.concat(lastMessage));
    // //         }
    // //     }, [lastMessage, setMessageHistory]);

    // // useEffect(() => {
    // //     if (signer === null) {
    // //         connect()
    // //     }
    // // }, [signer, connect])

    // return {
    //     socketUrl,
    //     connect
    // }
}

export default createContainer(useConnection)