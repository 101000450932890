import { useState } from 'react';
import ConnectionContainer from './Connection'
import {
    AppBar,
    Toolbar,
    Button,
    List,
    ListItem,
    Anchor,
} from 'react95';
//import chainsightLogo from 'react95/dist/images/logo.png'

function Navbar() {
    const [open, setOpen] = useState(false)

    const { address, connect } = ConnectionContainer.useContainer()

    return (
        <AppBar>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <Button
                        onClick={() => setOpen(!open)}
                        active={open}
                        style={{ fontWeight: 'bold' }}
                    >
                        <img
                            src="new_logo.png" 
                            alt='chainsight logo'
                            style={{ height: '20px', marginRight: 4 }}
                        />
                         Chainsight 
                    </Button>
                    {open && (
                        <List
                            style={{
                                width: '140px',
                                position: 'absolute',
                                left: '0',
                                top: '100%'
                            }}
                            onClick={() => setOpen(false)}
                        >
                            <ListItem>
                                <span role='img' aria-label='📋'>
                                📋
                                </span>
                                <Anchor href="https://docs.snowsight.chainsight.dev/">
                                    Docs</Anchor>
                            </ListItem>
                            <ListItem>
                                <span role='img' aria-label='💬'>
                                💬
                                </span>
                                <Anchor href="https://t.me/+5PstuZBjk3AzN2Jh">
                                    Telegram</Anchor>
                            </ListItem>
                            <ListItem>
                                <span role='img' aria-label='🐦'>
                                🐦
                                </span>
                                <Anchor href="https://twitter.com/ChainsightLabs">
                                    Twitter</Anchor>
                            </ListItem>
                            <ListItem>
                                <span role='img' aria-label='📊'>
                                📊
                                </span>
                                <Anchor href="https://dune.xyz/ChainsightAnalytics">
                                    Analytics</Anchor>
                            </ListItem>
                            <ListItem>
                                <span role='img' aria-label='🔌'>
                                🔌
                                </span>
                                <Anchor href="https://github.com/ChainsightLabs">
                                    Github</Anchor>
                            </ListItem>

                            
                        </List>
                    )}
                </div>
                <Button onClick={() => connect()}>
                    {
                        address === null ?
                            'Connect' :
                            address.slice(0, 10) + '...' + address.slice(40, 42)
                    }
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
