import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ConnectionContainer from './Connection'
import KeyContainer from './ApiKey'
import PingContainer from './Ping'
import WebSocketTestContainer from './WebSocketTester'
import { useClippy, ClippyProvider } from '@react95/clippy';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import original from "react95/dist/themes/original";
import powerShell from "react95/dist/themes/powerShell";
import tokyoDark from "react95/dist/themes/tokyoDark";
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";

import {
  styleReset,
} from 'react95';

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 700;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    margin: 0;
    padding: 0;
    background: teal;
    font-family: 'ms_sans_serif';
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <div>
      <GlobalStyles />
      <ThemeProvider theme={original}>



          <ConnectionContainer.Provider>

              <KeyContainer.Provider>
              <PingContainer.Provider>
                <App />
                </PingContainer.Provider>
              </KeyContainer.Provider>


          </ConnectionContainer.Provider>


      </ThemeProvider>

    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
