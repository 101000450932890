import logo from './logo.svg';
import { Panel, TextField, Counter, Window, Anchor, WindowContent, WindowHeader, Tabs, Slider, Tab, TabBody, LoadingIndicator, NumberField, Checkbox, Hourglass, Fieldset, Button } from 'react95';
import React, { async, useState, useCallback, useEffect } from 'react';
import io from 'async-await-websockets';
import Navbar from './NavBar'
import ConnectionContainer from './Connection'
import KeyContainer from './ApiKey'
import PingContainer from './Ping'
import WebSocketTestContainer from './WebSocketTester'
import './App.css';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useClippy, ClippyProvider } from '@react95/clippy';
import { ethers } from "ethers"
import { BigNumber } from 'ethers';

// function Example() {
//   const [count, setCount] = useState(0);

//           let web3Provider;
//         try {
//             web3Provider = new ethers.providers.Web3Provider(
//                 window.ethereum
//             );

//             web3Provider.send("eth_requestAccounts", null);
//             let localSigner = web3Provider.getSigner();
    
//             const message = "Sign this message to authenticate your wallet with Snowsight."
//             const signed_message = localSigner.signMessage(message)
            
//             const orig_address = ethers.utils.verifyMessage(message, signed_message)
//             console.log("signing address:", orig_address)
    
//             const handleClickChangeSocketUrl = useCallback(() =>
//             setSocketUrl('wss://demo.piesocket.com/v3/channel_1?api_key=oCdCMcMPQpbvNjUIzqtvF1d2X2okWpDQj4AwARJuAgtjhzKxVEjQU6IdCjwm&notify_self'), []);
    
//             const handleClickSendMessage = useCallback(() =>
//             sendMessage('Hello'), []);
    
//             setMessageHistory('test');

//         } catch (e) {
//             return;
//         }


//   // Similar to componentDidMount and componentDidUpdate:
//   useEffect(() => {
//     // Update the document title using the browser API
//     document.title = `You clicked ${count} times`;
//   });

//   return (
//     <div>
//       <p>You clicked {count} times</p>
//       <button onClick={() => setCount(count + 1)}>
//         Click me
//       </button>
//     </div>
//   );
// }


const Snowsight_ABI = require('./abi/SnowsightABI.json')

const provider = new ethers.providers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc')

var Snowsight = new ethers.Contract("0x727Dc3C412cCb942c6b5f220190ebAB3eFE0Eb93", Snowsight_ABI, provider);



function App() {
  useEffect(() => {
    document.title = "snowsight.exe";  
  }, []);


  const { address } = ConnectionContainer.useContainer()
  let connected = address === null ? false : true
  if (connected)
  {
    console.log("Wallet connected at address", address)
  }

  
  const TWO_SECONDS = 2000;

  useEffect(() => {
    const interval = setInterval(() => {

      statusFunc();
      rateFunc();
      console.log('Logs every minute');
    }, TWO_SECONDS);

  return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const [stateTab, setTab] = useState({
    activeTab: 2
  });

  const handleChange = (e, value) => handleFunc(value);

  const handleFunc = async (value) => {
    {
      setTab({ activeTab: value });
      rateFunc();
      paymentFunc(activePayTab, sliderState);
      statusFunc();
    }
  };
  const { activeTab } = stateTab;


  const [statePayTab, setPayTab] = useState({
    activePayTab: 2
  });

  const handlePayTabChange = (e, value) => handlePayTabFunc(value);

  const handlePayTabFunc = async (value) => {
    {
      setPayTab({ activePayTab: value });
      rateFunc();
      statusFunc();
      paymentFunc(value, sliderState);
    }
  };
  const { activePayTab } = statePayTab;



  

  const [stateSlider, setSlider] = useState({
    sliderState: 0
  });
  const handleSlider = (e, value, value2) => sliderFunc(value);
  const { sliderState } = stateSlider;
  const sliderFunc = (value) => {
    {
      setSlider({ sliderState: value });
      paymentFunc(activePayTab, value);
      statusFunc();
    }
  };


  const [stateEndBlock, setEndBlock] = useState({
    endBlockState: null
  });
  const { endBlockState } = stateEndBlock;

  const [stateStatus, setStatus] = useState({
    statusState: null
  });
  const { statusState } = stateStatus;

  const [stateTimeLeft, setTimeLeft] = useState({
    timeLeftState: 0
  });
  const { timeLeftState } = stateTimeLeft;

  const statusFunc = async () => {
    try
    {
      let web3Provider;
      {
        web3Provider = new ethers.providers.Web3Provider(
          window.ethereum
      ); 

      await web3Provider.send("eth_requestAccounts", null);
      let localSigner = web3Provider.getSigner();
      const addr = await localSigner.getAddress()
      console.log("addr test", addr);

        var paymentsFree = await Snowsight.payments(addr, 0);
        var paymentsStandard = await Snowsight.payments(addr, 1);
        var paymentsPriority = await Snowsight.payments(addr, 2);
        var isActive = false;

        var block_now = await provider.getBlock();
        var time_now =  block_now.timestamp;

        if (paymentsFree[0] === true)
        {
          if (time_now >= paymentsFree[1])
          {
            setEndBlock({ endBlockState: paymentsFree[1].toNumber()});
          }
          else
          {
            setEndBlock({ endBlockState: paymentsFree[1].toNumber()});
            setStatus({statusState: 'Trial'});
            setTimeLeft({timeLeftState: (paymentsFree[1] - time_now) / 60 / 60 / 24 });
            isActive = true;
          }
        }
        
        if (paymentsStandard[0] === true)
        {
          if (time_now >= paymentsStandard[1])
          {
            setEndBlock({ endBlockState: paymentsStandard[1].toNumber()});
          }
          else
          {
            setEndBlock({ endBlockState: paymentsStandard[1].toNumber()});
            setStatus({statusState: 'Standard'});
            setTimeLeft({timeLeftState: (paymentsStandard[1] - time_now) / 60 / 60 / 24 });
            isActive = true;
          }
        }

        console.log("settings: ", paymentsStandard);

        if (paymentsPriority[0] === true)// || settingsStandard.exists === true || settingsFree.exists === true)
        {
            if (time_now >= paymentsPriority[1])
            {
              setEndBlock({ endBlockState: paymentsPriority[1].toNumber()});
            }
            else
            {
              setEndBlock({ endBlockState: paymentsPriority[1].toNumber()});
              setStatus({statusState: 'Premium'});
              setTimeLeft({timeLeftState: (paymentsPriority[1] - time_now) / 60 / 60 / 24 });
              isActive = true;
            }
        }

        if (!isActive)
        {
          setEndBlock({ endBlockState: 0});
          setStatus({statusState: 'Inactive'});
          setTimeLeft({timeLeftState: 0});
        }

      }
    } catch (e) {
      console.log('Error on status.', e);
        return;
    }
  };


  const [stateRate, setRate] = useState({
    priorityRate: null
  });
  const { priorityRate } = stateRate;

  const [stateStandardRate, setStandardRate] = useState({
    standardRate: null
  });
  const { standardRate } = stateStandardRate;

  const [stateFreeRate, setFreeRate] = useState({
    freeRate: null
  });
  const { freeRate } = stateFreeRate;


  const rateFunc = async () => {
    {
      try {
      var settings = await Snowsight.tierSettings(2);
      console.log('new_rate: ', (settings[0]));
      setRate({ priorityRate: settings[0] });

      var settings2 = await Snowsight.tierSettings(1);
      setStandardRate({ standardRate: settings2[0] });

      var settingsFree = await Snowsight.tierSettings(0);
      setFreeRate({ freeRate: settingsFree[0]});

    } catch (e) {
      console.log('Error on rate func.', e);
        return;
    }
    }
  };



  const [statePayment, setPayment] = useState({
    activePayment: null
  });
  const { activePayment } = statePayment;


  const [stateActivePaymentEstimateDays, setActivePaymentEstimateDays] = useState({
    activePaymentEstimateDays: null
  });
  const { activePaymentEstimateDays } = stateActivePaymentEstimateDays;

  

  const paymentFunc = async (tier, slider_val) => {
    {
      var max_payment = await Snowsight.calculateMaxPayment(tier, {'from': address});
      var settings = await Snowsight.tierSettings(tier);
      var payment = 0;

      console.log("tier: ", tier);
      console.log("max_payment: ", max_payment);
      console.log("slider_val: ", slider_val);
      console.log("currentRate: ", settings[0] );
      if (tier == 1 || tier == 2)
      {
        if (slider_val == 0)
        {
            payment = settings[0].mul(60 * 60 * 24 * 3); 
        }
        if (slider_val == 1)
        {
            payment = settings[0].mul(60 * 60 * 24 * 7); 
        }
        if (slider_val == 2)
        {
            payment = settings[0].mul(60 * 60 * 24 * 15);
        }
        if (slider_val == 3)
        {
            payment = settings[0].mul(60 * 60 * 24 * 30);
        }
        if (slider_val == 4)
        {
            payment = settings[0].mul(60 * 60 * 24 * 45);
        }
        if (slider_val == 5)
        {
            payment = settings[0].mul(60 * 60 * 24 * 60);
        }
      }
      else
      {
          payment = settings[0].mul(60 * 60 * 24 * 1);
      }

      if (payment.gte(max_payment))
      {
          payment = max_payment;
      }

      console.log("current payment: ", payment);

      try
      {
        setPayment({activePayment: payment});
        var settings = await Snowsight.tierSettings(tier);
        console.log('new_rate2: ', payment);
        setActivePaymentEstimateDays({activePaymentEstimateDays: payment.div(settings[0]).div(60 * 60 * 24)});
      }
      catch (e)
      {
        console.log("error on payment: ", e);
       // setPayment({activePayment: payment});
      }
    }
  };




  const handlePayNow= async (hours) => {
    {
      let web3Provider;
      try {
        console.log('User clicked pay now');
          web3Provider = new ethers.providers.Web3Provider(
              window.ethereum
          ); 
          await web3Provider.send("eth_requestAccounts", null);
          let localSigner = web3Provider.getSigner();
          const addr = await localSigner.getAddress()
          console.log(addr);
          console.log("activePayTab", activePayTab);

          var Snowsight_Contract = new ethers.Contract("0x727Dc3C412cCb942c6b5f220190ebAB3eFE0Eb93", Snowsight_ABI, localSigner);
                    
          console.log("payment", activePayment);
          await Snowsight_Contract.pay(activePayTab, {'from': addr, 'value': activePayment});
          
      } catch (e) {
        console.log('Error on pay now.', e);
          return;
      }
    }
  };

  
  const handleClick= async (hours) => {
        let web3Provider;
       // try {
            web3Provider = new ethers.providers.Web3Provider(
                window.ethereum
            );

            await web3Provider.send("eth_requestAccounts", null);
            let localSigner = web3Provider.getSigner();
    
            const message = "Sign this message to authenticate your wallet with Snowsight."
            const signed_message = await localSigner.signMessage(message)
            
            const orig_address = ethers.utils.verifyMessage(message, signed_message)
            console.log("signing address:", orig_address)

            const io = require('async-await-websockets')
            const socket = await io.default("ws://avax.chainsight.dev:8589");
            const result = socket.asyncEmit("example-async", { signed_key: signed_message })
            console.log('websocket:', result);
    

       // }
        // catch (e) {
        //   console.log('Error on websocket test', e);
        //     return;
        // }
  }



  //var { connectionStatus, t2, websocket_connect } = WebSocketTestContainer.useContainer();
  var connectionStatus = null

  //const { clippy } = useClippy();



  const [state2, setState2] = useState({
    activeTab2: 0
  });
  
  const handleChange2 = (e, value) => setState2({ activeTab2: value });
  
  const { activeTab2 } = state2;

  const [state3, setState3] = useState({
    activeTab3: 0
  });
  
  const handleChange3 = (e, value) => setState3({ activeTab3: value });
  
  const { activeTab3 } = state3;


  // const { test1, test2, test3 } = KeyContainer.useContainer()
  // console.log(test1);
  // console.log(test2);
  // console.log(test3);
  var ms = 3;
  //var { ms, doPingReq } = PingContainer.useContainer();
  //doPingReq()
  console.log('ms: ', ms);

  const [count, setCount] = useState(0);
  // const handleClick = () => setCount(count + 1);

  const loremIpsum = "testtest";

  return (
    <>
      <div style={{ width: '100%', paddingTop: '100px', display: 'flex',  justifyContent:'center', alignItems:'center',}}>

      



      <Window style={{ width: 750 }}>
          <WindowHeader>🏔️ snowsight.exe</WindowHeader>
          <WindowContent style={{  alignItems: 'center', justifyContent: 'center'}}>

          {
            
            <><Tabs value={activeTab} onChange={handleChange} onClick={null} >
              {
 //             <Tab value={0}>About</Tab>
              }
              <Tab value={2}>Manage</Tab>
              {
             // <Tab value={1}>Test</Tab>
              }
             // <Tab value={4}>Docs</Tab>
              
              <Tab value={5}>FAQ</Tab>
              <Tab value={6}>Contracts</Tab>
              
              {
              //<Tab value={5}>FAQ</Tab>
              //<Tab value={6}>Contracts</Tab>
              //<Tab value={7}>Roadmap</Tab>
              //<Tab value={8}>Disclaimer</Tab>
              }
            </Tabs>
            <TabBody style={{ height: 685 }}>
              {
                activeTab === 2 && address === null && (     
                           <><Hourglass style={{display: 'flex', justifyContent:'center',alignItems:'center'}} />
                            <p style={{ textAlign: 'center', marginBottom: '1.5rem' }}> Connect your wallet...</p>
                            <LoadingIndicator isLoading />
                            <br /> <br /> 
                            <span role='img' aria-label='📋 '>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 📋  &nbsp;
                                </span>
                                <Anchor href="https://docs.snowsight.chainsight.dev/">
                                     Docs </Anchor>
                            

                        </>)
              }
              {
                activeTab === 2 && address !== null && (    
              <div>
                <Fieldset label='Account Status:' >
                <Fieldset fullWidth style={{ display: 'flex', backgroundColor: 'white'} }>
                    Account: {address}
                    <br />
                    Payment Tier: {statusState}
                    <br />
                    End Timestamp (Unix): {endBlockState}
                    <br />
                    Time Left:  ~ {timeLeftState} Days
                    <br />
                    
                  </Fieldset>
                  </Fieldset>
                  <br />
                  <Fieldset label='Payment Tier Rates:' >
                  <Fieldset fullWidth style={{ display: 'flex', backgroundColor: 'white'} }>
                    Premium: {priorityRate * 1e-18 * 60 * 60 * 24} AVAX per Day<br />
                    Standard: {standardRate * 1e-18 * 60 * 60 * 24} AVAX per Day<br />
                    Trial: {freeRate * 1e-18 * 60 * 60 * 24} AVAX per Day
                    </Fieldset>
                  </Fieldset>
                  <br />
                  <Fieldset label='Pay by Time:'>
                  <div style={{ padding: '0.5em 0 0.5em 0'}}></div>

                  <Tabs value={activePayTab} onChange={handlePayTabChange} onClick={null} >

                  <Tab value={0}>Trial</Tab>
                  <Tab value={1}>Standard</Tab>
                  <Tab value={2}>Premium</Tab>


                </Tabs>
                <TabBody style={{ height: 100 }}>
                {
                  (activePayTab === 2 || activePayTab === 1) && (
                    <Slider
                      size='580px'
                      min={0}
                      max={5}
                      step={1}
                      style={{ display: 'flex', }}
                      onChangeCommitted={ handleSlider}
                      defaultValue={1}
                      marks={[
                        { value: 0, label: '~ 3 days' },
                        { value: 1, label: '~ 7 days' },
                        { value: 2, label: '~ 15 days'  },
                        { value: 3, label: '~ 30 days' },
                        { value: 4, label: '~ 45 days' },
                        { value: 5, label: '~ 60 days' },
                      ]}
                    />
                  )
                }
                {
                  activePayTab === 0  && (
                    <Slider
                      size='580px'
                      min={0}
                      max={2}
                      step={1}
                      style={{ display: 'flex', }}
                      onChangeCommitted={ handleSlider}
                      defaultValue={1}
                      marks={[
                        { value: 0, label: '' },
                        { value: 1, label: '~ 1 day' },
                        { value: 2, label: '' },
                      ]}
                    />
                  )
                }


                </TabBody>

                </Fieldset>
               <br></br>
                
                <Button fullWidth onClick={handlePayNow}>

                {activePayment !== null && activePayTab === 2 &&  (
                    'Pay ' + activePayment * 1e-18 + ' AVAX for Premium Tier  (~' + activePaymentEstimateDays + ' Days)'
                )}
                {activePayment !== null && activePayTab === 1 &&  (
                    'Pay ' + activePayment  * 1e-18 + ' AVAX for Standard Tier  (~' + activePaymentEstimateDays + ' Days)'
                )}
                {activePayment !== null && activePayTab === 0 &&  (
                    'Pay ' + activePayment  * 1e-18 + ' AVAX for Trial Tier  (~' + activePaymentEstimateDays + ' Days)'
                )}
                {activePayment === null && (
                    '  Loading...'
)}
  </Button> 

                
                
                    
                </div>
              )}
              {activeTab === 0 && (
                <div>
                  <Fieldset label='🏔️ Snowsight 🏔️' style={{  alignItems: 'center', justifyContent: 'center', }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                  
                  &nbsp;&nbsp;&nbsp;&nbsp;😍 A mempool streaming service for MEV searchers on Avalanche 😍 
                  
                  <br />
                      
                  </Fieldset>
                    </Fieldset>
                    <br />
                    <Fieldset label='🖥️ Server 🖥️' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <Fieldset style={{ backgroundColor: 'white'}}>
                   
                    Location: AWS US-east-1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
                    Name: avax.chainsight.dev:8589
                    <br />
                    {
                        ms !== undefined && ('Status: Online') 
                    }
                    {
                        ms === undefined && ('Status: Offline')
                    }  
                    
                    <br />
                    {
                      //Ping: 
                    }
                    {
                      //  ms !== undefined && (ms + ' ms') 
                    }
                    {
                      //  ms === undefined && <> Unknown!</>
                    }          
                    
                    </Fieldset>
                  </Fieldset>

                  <br />
                  <Fieldset label='📜 Instructions 📜' style={{ paddingLeft: '20px', alignItems: 'center', justifyContent: 'center', width: '630px' }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                      1. Connect wallet <br/>
                      2. Manage account on Snowsight Smart Contract <br/>
                      3. Test account websocket connection <br/>
                      4. Learn how to use Snowight with your favorite web3 library <br/>              
                      5. ?????? <br/>    
                      6. Profit!  
                      </Fieldset>
                  </Fieldset>
                  <br />
                        
                        <br />

                </div>
              )}
              {
                activeTab === 1 && address === null && (     
                           <><Hourglass style={{display: 'flex', justifyContent:'center',alignItems:'center'}} />
                            <p style={{ textAlign: 'center', marginBottom: '1.5rem' }}> Connect your wallet...</p>
                            <LoadingIndicator isLoading />
                        </>)
              }
              {activeTab === 1 && address !== null && (
                <div>
                <Fieldset label='Websocket Tester:'>
                 

                 <Button onClick={handleClick} style={{display: 'flex', justifyContent:'center'}}> Start Test</Button>

                </Fieldset>
              </div>

              )}
              {activeTab === 4 && (
                <div><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                                    <span role='img' aria-label='📋 '>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 📋  &nbsp;
                                </span>
                                <Anchor href="https://docs.snowsight.chainsight.dev/">
                                     Docs </Anchor>
                  </div>

                

              )}
              {activeTab === 5 &&  (
                <div>
                  <Fieldset label='Do I have to pay using this UI?' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                    Nope, your bot should integrate to pay the Snowsight contract periodically.
                    </Fieldset>
                  </Fieldset>
                  <br />
                  <Fieldset label='How many nodes does Snowsight have?' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                    We are running [redacted] nodes.  
                    Revealing too much about our backend now would result in loss of competitive advantage.  Our backend aggregrates multiple node's p2p in pure Rust. 
                    For now, you will have to trust that Snowsight is fast enough for effective PGA. 
                    </Fieldset>
                    </Fieldset>
  
                  <br />
                  <Fieldset label='Why AWS US-east-1?' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                    A majority of AVAX validator nodes are in AWS US-east-1.  Running here provides the best latency.
                    </Fieldset>
                  </Fieldset>
                  <br />
                  <Fieldset label='My ping is high, wat do?' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
                  <Fieldset style={{ backgroundColor: 'white'}}>
                    Run your bot in AWS US-east-1.  Ping will be less than 150 us.
                  </Fieldset>
                  </Fieldset>

                  
              </div>

              )}





              {activeTab === 6 &&  (
                <div>
                <Tabs value={activeTab2} onChange={handleChange2}>
                    <Tab value={0}>SnowsightManager.sol</Tab>
                  </Tabs>
                  <TabBody  > Address: 0x727Dc3C412cCb942c6b5f220190ebAB3eFE0Eb93<br />
                 
                    </TabBody>
              </div>

              )}
              {activeTab === 7 &&  (
                <div>


                    



                   
              </div>

              )}
            </TabBody>

        </>}
          </WindowContent>
        </Window>


        

      </div>
      <br />
      <Navbar />

      
    </>
  );
}

export default App;
