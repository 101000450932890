
import { useCallback, useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { ethers } from "ethers"

//import  ping  from 'ping';
//import { debug } from "webpack";


function useConnection() {

    const [ping2, setPing] = useState(0)

    const doPingReq = useCallback(async () => {
        
        try {
            /**
             *
             * Get RTT (Round-trip delay time)
             *
             * @static
             * @param {string} ipAddress - For example : 8.8.8.8
             * @param {Object} option - Some optional operations
             * @param {number} option.timeout - timeout
             * @returns
             * @memberof Ping
             */
             const option = { timeout: 1000 };
            //  const {
            //     receivedNetworkSpeed,
            //     sendNetworkSpeed,
            //     receivedNetworkTotal,
            //     sendNetworkTotal
            //   } = await Ping.getTrafficStats();
            // const ms = receivedNetworkSpeed;
            const ms = 42;

            // const ms = await Ping.start('8.8.8.8',option);
            setPing(ms);
            console.log('ms test:', ms);
          } catch (error) {
            console.log('special code ', error.code, error.message);
          }

    }, [])

    useEffect(() => {
        if (ping2 === null) {
            doPingReq()
        }
    }, [ping2, doPingReq])

    return {
        ping2,
        doPingReq
    }
}

export default createContainer(useConnection)